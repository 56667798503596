import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import { openSiteForm } from '@shared/visitor/form/index.js';
import { Pay } from '@/services';
import { checkPayStatus as checkPayStatusByAjax, getOneSiteForm } from '@/api/form';
import { getModuleListByIds } from '@/api/modules/index.js';

function checkPayStatus({ siteFormSubmitId, retryTimes }, topResolve, topReject) {
    return new Promise((resolve, reject) => {
        if (!topResolve) {
            topResolve = resolve;
        }
        if (!topReject) {
            topReject = reject;
        }
        checkPayStatusByAjax({
            siteFormSubmitId,
        })
            .then((data) => {
                if (data.success) {
                    topResolve(data);
                } else {
                    if (retryTimes > 0) {
                        setTimeout(() => {
                            checkPayStatus(
                                {
                                    siteFormSubmitId,
                                    retryTimes: retryTimes - 1,
                                },
                                topResolve,
                                topReject
                            );
                        }, 1000);
                    } else {
                        topResolve(data);
                    }
                }
            })
            .catch((err) => {
                topReject(err);
            });
    });
}

export class FormPay {
    static async checkFormPayParam() {
        if (window._store.state.manageMode) return;
        const href = Fai.top.window.location.href;
        const formId = Fai.getUrlParam(href, 'formId');
        let success = Fai.getUrlParam(href, 'success');
        const moduleId = Fai.getUrlParam(href, 'moduleId');
        const random = Fai.getUrlParam(href, 'random');
        const bssId = Fai.getUrlParam(href, 'bssId');
        if (!formId || !success || !moduleId || !bssId) return;

        // 增加localStorage防止多次刷新
        const localRandom = window.localStorage.getItem(Pay.PAY_RESULT_RANDOM_LOCAL_STORAGE_KEY);
        if (!localRandom || localRandom != random) return;
        window.localStorage.removeItem(Pay.PAY_RESULT_RANDOM_LOCAL_STORAGE_KEY);

        let payingTipsModal = null;
        const isPayPay = Boolean(Fai.getUrlParam(href, 'PayerID'));
        if (isPayPay) {
            payingTipsModal = Vue.prototype.$modal.confirm({
                footer: null,
                title: LS.paying || '请稍等，正在支付中...',
            });
        }
        const handlePayPalPayStatusError = (success) => {
            if (isPayPay) {
                payingTipsModal.destroy();
                if (!success) {
                    Vue.prototype.$modal.confirm({
                        title: LS.payError || '支付异常，请联系商家确认订单信息',
                    });
                    return true;
                }
            }
            return false;
        };

        try {
            const data = await checkPayStatus({
                siteFormSubmitId: bssId,
                retryTimes: isPayPay ? 60 : 1,
            });
            success = data.success ? '1' : '0';
        } catch (err) {
            success = '0';
        }

        if (handlePayPalPayStatusError(success === '1')) {
            return;
        }

        const jumpToSuccessPage = (url, isBlank) => {
            //3秒后跳转
            setTimeout(() => {
                const hasUrl = url && !url.includes('javascript:void(0)');
                if (hasUrl) {
                    if (isBlank) {
                        window.open(url, '_blank');
                    } else {
                        location.href = url;
                    }
                }
            }, 3000);
        };

        // 弹窗的话 moduleId和formId是一样的
        const isPopup = moduleId == formId;
        if (success == '1') {
            let submitTips = LS.siteFormSubmitOk;
            if (isPopup) {
                getOneSiteForm({
                    formId,
                    ram: Math.random(),
                }).then((res) => {
                    successMessage(submitTips, true);
                    if (res.success === true) {
                        const url = res?.info?.other?.p?.lu ?? '';
                        const isBlank = res.info.other?.p?.ib;
                        if (url) {
                            jumpToSuccessPage(url, isBlank);
                        }
                    }
                });
                return;
            }
            getModuleData(moduleId).then((res) => {
                if (res.moduleList.length == 0) {
                    successMessage(submitTips, true);
                    return;
                }
                const module = res.moduleList[0].moduleData;
                if (module.showForm && module.buddyId) {
                    submitTips = module.siteOpenFormSubmitOk;
                }
                successMessage(submitTips, true);
                jumpToSuccessPage(module.prop3.url, module.prop3.ib);
            });
        } else {
            // 支付失败
            const submitErrorTips = LS.siteFormSubmitError;
            warningMessage(submitErrorTips, true);
            if (isPopup) {
                openSiteForm(formId);
                return;
            }
            setTimeout(() => {
                $('html').animate({
                    scrollTop: $(`#module${moduleId}`).offset().top - 40,
                });
            }, 2000);
        }

        function getModuleData(moduleId) {
            moduleId = parseInt(moduleId);
            return new Promise((resolve, reject) => {
                if (!moduleId) reject();
                getModuleListByIds({
                    colId: window._store.state.colId,
                    href: window._store.state.fullUrl,
                    moduleIdList: $.toJSON([moduleId]),
                }).then((data) => {
                    data.success ? resolve(data) : reject(data);
                });
            });
        }
    }
}
