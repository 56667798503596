import { ServiceFactory } from './factory';
import { memorize } from '@jz/utils';

const SERVICE_INTERFACE = 'DatePicker';

const getDatePickerScript = memorize(async () => {
    const { initDatePickerScript } = await import(/* webpackChunkName: "jqueryDatepicker" */ '@/libs/datepicker');
    return initDatePickerScript;
});

let _inited = false;

export class DatePickerService {
    constructor() {}

    async initDatePicker() {
        if (window.$ && window.$.datepicker !== undefined) {
            return;
        }
        const initDatePickerScript = await getDatePickerScript();
        if (_inited) {
            return;
        }
        initDatePickerScript();
        _inited = true;
    }
}

ServiceFactory.register(SERVICE_INTERFACE, {
    interfaceClass: DatePickerService,
});
