import { installModal } from '@jz/biz-shared';

export const Payssion = {
    dialog: null,
    checkout: {
        async open({ currency, amount }) {
            if (!Vue.prototype.$modal) {
                installModal(Vue);
            }
            if (this.dialog) {
                this.dialog.destroy();
            }
            const { default: popup } = await import(/* webpackChunkName: "payssion-checkout" */ './checkout/popup.vue');

            return new Promise((resolve, reject) => {
                this.dialog = Vue.prototype.$modal.showDialog({
                    width: '590px',
                    content: {
                        render: (h) =>
                            h(popup, {
                                props: {
                                    currency,
                                    amount,
                                },
                                on: {
                                    confirm: (result) => {
                                        resolve(result);
                                        this.dialog.destroy();
                                    },
                                },
                            }),
                    },
                    onClose: () => {
                        reject();
                    },
                });
            });
        },
    },
};
