import { Module } from '@shared/visitor/module/index.js';
import { banner } from '@/components/grid/banner/index.js';
import { browser } from '@jz/utils';
import { getLinkFlvDom, getModuleFlvDom } from '@api/flv/index.js';
/**
 * [checkDialogVideoIsIframe 弹层视频是否是iframe]
 * @function name checkDialogVideoIsIframe
 * @param id  {Number}    [id 视频模块id]
 *
 * @return  {String}      [return iframe得地址]
 */
const checkDialogVideoIsIframe = function (id, isLinkFlv) {
    //由于链接视频id永远是0，所以不缓存。
    if (Comm.getDevice() == 'mobi' && !isLinkFlv) {
        const iframe = $(`#bg_video_mask${id} .clearmb iframe`); // 获取iframe
        const hasIframe = iframe.length !== 0; // 弹层视频是否是iframe
        if (hasIframe) {
            iframe.attr({
                name: 'iframeContainer' + id + '',
                id: 'iframeContainer' + id + '',
            }); // 加上id
            if (typeof Fai.top.iframeSrcMap === 'undefined') {
                Fai.top.iframeSrcMap = {};
            }
            if (!Fai.top.iframeSrcMap[id]) {
                // 获取iframe得地址存起来
                Fai.top.iframeSrcMap[id] = iframe.attr('src');
            } else {
                iframe.attr('src', Fai.top.iframeSrcMap[id]);
            }
            $(`#bg_video_mask${id} .clearmb`).addClass('iframe_video');
        }
    }
};

// 自适应容器
const fluidFlv = function (id) {
    let isNewFlvId = !!$('#new_direction_video_' + id).length,
        flvId = isNewFlvId ? '#new_direction_video_' : '#new_video_',
        $flv = $(flvId + id);

    if (!$flv.length || !$flv.is(':visible')) {
        return;
    }

    Fai.top.videojs(flvId + id).on('canplay', function () {
        var videoJs = Fai.top.videojs(flvId + id),
            H = videoJs.videoHeight(),
            W = videoJs.videoWidth();
        $('.online_flv_' + id).css('padding-bottom', (H / W) * 100 + '%');
        banner.fixBannerOverflowHeight();
    });
};

/**
 * 苹果手机上的(qq浏览器、百度浏览器app)，会劫持视频插件。需要用原生的播放才可以。
 */
const isNoAllowVideoJs = function () {
    const isPhone = browser.isIphone();
    if (!isPhone) {
        return false;
    }
    return browser.isQQ() || browser.isBaiDu();
};

/**
 *
 * @author  Mackie 20190911
 * @function name renderDialog
 * @description [renderDialog 渲染弹窗视频]
 * @param   {Dom}  videoHtml    [videoHtml description]
 * @param   {Number}  moduleId     [moduleId description]
 * @param   {String}  extClass     [extClass description]
 * @param   {String}  opacityHtml  [opacityHtml description]
 * @param   {String}  zIndexHtml   [zIndexHtml description]
 * @param   {String}  result       [result description]
 * @return  {undefined}               [return description]
 */

const renderDialog = function (videoHtml, moduleId, extClass, opacityHtml, zIndexHtml, result, isLinkFlv) {
    let flvDom;
    if (!result.success) {
        Fai.ing('视频弹窗打开失败，请稍候重试', false);
    }
    flvDom = result.info.sb;
    if (!videoHtml) {
        videoHtml =
            "<div id='bg_video_mask" +
            moduleId +
            '\'><div class="popupBg popupBgForWin ' +
            extClass +
            '" style=\'' +
            opacityHtml +
            zIndexHtml +
            "' >" +
            '</div>';
        videoHtml +=
            '<div class="video_dialog">' +
            "<div class='dialogVideoDiv'>" +
            "<div class='cancelDialogVideo' onclick='JZ.cancelDialogVideo(\"" +
            moduleId +
            '")\'></div>' +
            '<div class="clearmb">' +
            flvDom +
            '</div>' +
            '</div>' +
            '</div>';
        if (window._store.state.manageMode && Comm.getDevice() == 'mobi') {
            $(videoHtml).appendTo('.jz_web_container');
        } else {
            $(videoHtml).appendTo('body');
        }
        if (Comm.getDevice() == 'mobi') {
            $('.cancelDialogVideo').css({
                right: '0px',
                left: '20px',
            });
            checkDialogVideoIsIframe(moduleId, isLinkFlv);
        }
        return;
    }
    if (Comm.getDevice() == 'mobi') {
        checkDialogVideoIsIframe(moduleId, isLinkFlv);
        $('#bg_video_mask' + moduleId + '').show();
    }
};

const dialogVideo = async function (moduleId, opts = {}) {
    var module;

    if (!module) {
        // 不需要每次都重新去新起一个 引起重排重绘 by mackie
        module = new Module(moduleId);
    } //背景变灰

    //背景变灰
    var opacityHtml = '',
        zIndexHtml = '',
        extClass = extClass || '',
        zIndex = '9500',
        flvData,
        isLinkFlv = !!opts.isLinkFlv;

    if (zIndex) {
        zIndexHtml = ' z-index:' + (zIndex - 1);
    }

    let result = '';

    if (isLinkFlv) {
        result = await getLinkFlvDom({
            moduleId: moduleId,
            flvType: opts.flvType,
            flvCode: opts.flvCode,
        });
    } else {
        let manageModeData = {};

        if (window._store.state.manageMode) {
            // 管理态下，需要实时展示，不能直接取数据库的数据
            flvData = module.conf;
            let videoNetUrl = flvData.prop5.nu;
            if (opts.isYoutube) {
                videoNetUrl = opts.youtubeUrl;
            }
            manageModeData = {
                videoType: flvData.prop0,
                poster: flvData.pUrl,
                url: flvData.url,
                videoNetUrl,
                flagUploadPost: Comm.getBitMemory(flvData.flag, 0x1),
            };
        }

        result = await getModuleFlvDom({
            moduleId: moduleId,
            ...manageModeData,
        });
    }

    var videoHtml = Fai.top.$('#bg_video_mask' + moduleId + '')[0]; // dom只渲染一次 不需要每次请求一次渲染一次 by mackie

    // dom只渲染一次 不需要每次请求一次渲染一次 by mackie
    if (Comm.getDevice() == 'pc' || isLinkFlv) {
        // pc端重新渲染弹层
        // 由于链接视频的moduleId都是0，所以，每次都是重新取
        Fai.top.$('#bg_video_mask' + moduleId + '').remove();
        videoHtml = '';
    }

    renderDialog(videoHtml, moduleId, extClass, opacityHtml, zIndexHtml, result, isLinkFlv); // 渲染dom函数

    // 渲染dom函数
    if (Fai.top.$('.dialogVideoDiv video')[0] == undefined) {
        return;
    }

    if (isNoAllowVideoJs()) {
        // 使用原生video播放。
        return;
    } // mp4切换重新初始化 销毁之前的，重新实例化

    // mp4切换重新初始化 销毁之前的，重新实例化
    var videojsDom = Fai.top.videojs('#new_video_' + moduleId);

    if (!Fai.top.$('#new_video_' + moduleId + '_html5_api').length) {
        videojsDom.dispose();
    }

    Fai.top.videojs('#new_video_' + moduleId).ready(function () {
        var myPlayer = this;
        myPlayer.controlBar.volumeMenuButton.hide();
        myPlayer.on('play', function () {
            $('#new_video_' + moduleId + ' .vjs-big-play-button').fadeOut();
        }); //暂停--播放完毕后也会暂停 加上父级 避免作用到其他模块

        //暂停--播放完毕后也会暂停 加上父级 避免作用到其他模块
        myPlayer.on('pause', function () {
            $('#new_video_' + moduleId + ' .vjs-big-play-button').fadeIn();
        }); // 结束

        // 结束
        myPlayer.on('ended', function () {});
        $('.dialogVideoDiv').find('div').eq(1)[0].style.outline = 0;

        if ($('.dialogVideoDiv').find('.moduleHttpTip').length == 1) {
            $('.dialogVideoDiv').css('background-color', 'white');
        }
    });
};

// 视频播放完后，回到片头
const videoEndAndReplay = function (id) {
    var thisVideo = document.getElementById('new_video_' + id);

    if (thisVideo) {
        // 结束事件
        thisVideo.onended = function () {
            // 视频重新加载（变相回到片头，因为没有相关接口）
            !!this.load && this.load();
        };
    }
};

export { dialogVideo, fluidFlv, videoEndAndReplay }; //video 模块懒加载
