import { _loadImg } from '@/site/initSectionObserver';
import { tick } from '@/site/shared/index.js';
import { initLazyloadTwo, sectionObserver } from '@/site/shared/sectionObserver/index.js';
import { browser } from '@jz/utils';
/**
 * @function name updateLazyload
 * @description 实例lazyload
 * @author mikey.chuhui
 * @date 2019-12-16
 * @param options {Object} dom对象
 */
function updateLazyload(el) {
    Vue.nextTick(() => {
        let maxCallTimes = 120;
        let t = tick(() => {
            // 轮询 直到观察器存在或者大于120次 在edge下 偶尔会出现观察器未初始化就调用
            t.times++;

            if (t.times >= maxCallTimes || sectionObserver()) {
                t.clearTick();
                t = null;
                sectionObserver() && initLazyloadTwo(el);
            }
        });
    });
}
/**
 * @function name setImgAttribute
 * @description 设置图片属性
 * @author mikey.chuhui
 * @date 2020-07-24
 * @param el {Object} dom对象
 * @param src {String}
 */

function setImgAttribute(el, src) {
    const isImgTag = el.localName.toLowerCase() === 'img';
    if (!src) {
        if (isImgTag) {
            el.setAttribute('src', '');
        } else {
            el.style.backgroundImage = '';
        }
        return;
    }
    if (!isImgTag) {
        el.style.backgroundImage = `url(${src})`;
    } else {
        el.setAttribute('src', src);
    }
}
/**
 * @function name setImgLazyloadAttribute
 * @description set元素的属性
 * @author mikey.chuhui
 * @date 2019-12-16
 * @param el {Object} 绑定的dom
 * @param opts {Object}  传入的对象
 * @param defaultSrc {String}  默认占位图
 */

function setImgLazyloadAttribute(el, opts, defaultSrc) {
    const noLazyBrowser = navigator.userAgent ? navigator.userAgent.toLowerCase().includes('baiduboxapp') : false;
    const isNoLazy = noLazyBrowser;
    const { src } = opts;
    el.classList.add('jz_img_lazyload');
    setImgAttribute(el, defaultSrc);
    if (isNoLazy) {
        _loadImg(el, src);
        return false;
    } else {
        el.setAttribute('src-original', src);
    }

    const { naturalWidth, naturalHeight } = opts;
    if (naturalWidth) {
        el.setAttribute('natural-width', naturalWidth);
    }
    if (naturalHeight) {
        el.setAttribute('natural-height', naturalHeight);
    }
    return true;
}
/**
 * @function name updateElement
 * @description 更新dom
 * @author mikey.chuhui
 * @date 2019-12-16
 * @param {*} el 绑定的dom
 */

function updateElement(el) {
    if (!el.getAttribute('data-loaded')) {
        updateLazyload(el);
    }
}

const isSameSrc = (el, src) => {
    const isImgTag = el.localName.toLowerCase() === 'img';
    if (isImgTag) {
        return el.getAttribute('src') === src;
    } else {
        const [, url = ''] = /url\(['"](.+?)['"]\)/g.exec(el.style.backgroundImage || '');
        return url === src;
    }
};

/**
 * @function name v-lazyload2
 * @description
 * :src-original="src" v-lazyload2="{src,id}"
 * src：String 图片地址  id：Number 模块id
 * vue 指令
 * @example
 * <img :src-original="src" v-lazyload2="{src,id}" />
 */

export function lazyload2(el, binding) {
    if (window._store.state.isMaterialLazyLoad) {
        return;
    }

    const { value = {}, oldValue = {} } = binding;
    if (value === null) {
        return;
    }

    const noChange = value.id === oldValue?.id && value.src === oldValue?.src;

    if (!noChange) {
        el.removeAttribute('data-loaded');
        setImgAttribute(el, '');
    }

    if (!binding.value || !binding.value.src || noChange) {
        return;
    }

    const isIE = browser.isIE();
    if (isIE) {
        // IE11 简单处理，不使用观察器
        _loadImg(el, binding.value.src);
        return;
    }

    // 是图片组件并且支持loading属性.则不走业务的懒加载,原生进行懒加载
    if (
        el.classList.contains('j__imagecomponet') &&
        'loading' in HTMLImageElement.prototype &&
        el.getAttribute('loading') == 'lazy'
    ) {
        return;
    }

    const isLoaded = el.getAttribute('data-loaded');
    const defaultSrc = `${window._store.state.resRoot}/image/rimage/fromSite/loading/dot.gif`;

    if (!isLoaded) {
        // 加载完毕则不走
        let isOk = setImgLazyloadAttribute(el, binding.value, defaultSrc);
        if (isOk) {
            updateElement(el, binding.value);
        }
    } else {
        if (!isSameSrc(el, binding.value.src || defaultSrc)) {
            setImgAttribute(el, binding.value.src);
        }
    }
}
