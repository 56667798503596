import { observeAnimate } from '@/site/shared';
import { getIsOpenRemoveUrlArg, handleSetUrlArgToCookie } from '@/site/shared/cookie/index.js';
import { device } from '@/site/shared/device/index.js';
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';

const banner = {};

function isPcDevice() {
    return Comm.getDevice() === 'pc';
}

banner.config = {
    update() {
        banner.bannerInfo =
            (window._store.state.manageMode && window._store.state.responsiveInfo.banner) || banner.bannerInfo;
        let type = isPcDevice() ? banner.bannerInfo.pc : banner.bannerInfo.mbct,
            showTime = isPcDevice() ? banner.bannerInfo.pcst : banner.bannerInfo.mbcst;

        function getAnimateTime() {
            let type = isPcDevice() ? banner.bannerInfo.pc : banner.bannerInfo.mbct;
            return type == 1 ? banner.bannerInfo.pccs * 1000 : Comm.getDevice() == 'pc' ? 1500 : 600;
        }

        function getIntervalTime() {
            let type = isPcDevice() ? banner.bannerInfo.pc : banner.bannerInfo.mbct,
                showTime = isPcDevice() ? banner.bannerInfo.pcst : banner.bannerInfo.mbcst;
            return getAnimateTime() + (type == 1 ? showTime * 1000 : 4500);
        }

        return {
            picLen: $('.banner_pic_group:visible .banner_pic_item_wrap').length,
            scrollWidth: 100,
            animateTime: getAnimateTime,
            showTime: type == 1 ? showTime * 1000 : 4500,
            intervalTime: getIntervalTime,
            style: banner.bannerInfo.s,
            autoAnimate: type != 2,
            curIndex: 0,
            timer: 0,
            getTransitionType: () => banner.bannerInfo.sa,
        };
    },
    timer: 0,
    setTimer(val) {
        this.timer = val;
    },
    getTimer() {
        return this.timer;
    },
    clearTimer() {
        clearTimeout(this.timer);
        this.setTimer(0);
    },
    curIndex: 0,
    setCurIndex(val) {
        return (this.curIndex = val);
    },
    getCurIndex() {
        return this.curIndex;
    },
};

banner.arrowSwitch = function (info) {
    banner.bannerInfo =
        banner.bannerInfo || info || (window._store.state.responsiveInfo && window._store.state.responsiveInfo.banner);

    const config = banner.config.update();
    const $banner = $('.jz_banner');
    const $bannerGroup = $('.banner_pic_group:visible');
    const _shortLine = $('.pic_short_line_btn:visible');
    const shortLineActivity = 'pic_short_line_btn_activity';
    const visibleBannerZoneStr = '.banner_pic_group:visible ';
    let curIndex = 0;
    let isInTransition = false;
    const animationEvents = [];

    const setIsInTransition = (val) => {
        isInTransition = val;
        eventBus.$emit('banner:isInTransition', val);
    };

    function nextClick() {
        const picLen = $(visibleBannerZoneStr + '.banner_pic_item_wrap').length;
        const _shortLine = $('.pic_short_line_btn:visible');

        if (isInTransition || picLen <= 1) {
            return false;
        }
        curIndex = banner.config.getCurIndex();
        var to = 0;
        if (curIndex == picLen - 1) {
            // 上限
            curIndex = -1;
        }
        to = curIndex + 1;
        curIndex = to == 0 ? picLen - 1 : curIndex;
        slide(to, 1, curIndex);
        if (_shortLine.length) {
            _shortLine.eq(to).addClass(shortLineActivity).siblings().removeClass(shortLineActivity);
        }
    }

    function prevClick() {
        var picLen = $(visibleBannerZoneStr + '.banner_pic_item_wrap').length;

        if (isInTransition || picLen <= 1) {
            return false;
        }
        var to = 0;
        if (curIndex == 0) {
            // 上限
            curIndex = picLen;
        }
        to = curIndex - 1;
        curIndex = to == picLen - 1 ? 0 : curIndex;
        slide(to, -1, curIndex);
    }

    function initMobiBannerTouch() {
        $banner.off('swipeleft.l').on('swipeleft.l', '.banner_pic_group:visible', function () {
            nextClick(); // 向右划动事件
        });
        $banner.off('swiperight.r').on('swiperight.r', '.banner_pic_group:visible', function () {
            prevClick(); // 向左划动事件
        });
    }

    function setup() {
        update();

        var picLen = $(visibleBannerZoneStr + '.banner_pic_item_wrap').length;

        if (picLen < 2) {
            return;
        }

        $banner.off('click.next').on('click.next', '.pic_arrow_btn_next', nextClick);
        $banner.off('click.prev').on('click.prev', '.pic_arrow_btn_prev', prevClick);
        $banner.off('click.s', '.pic_short_line_btn').on('click.s', '.pic_short_line_btn:visible', dotSlide);

        // mobi
        initMobiBannerTouch();

        if (config.autoAnimate) {
            autoPlay();
            initAutoPlayEvent();
        } else {
            kill();
        }
    }

    const removeAnimationEventItem = (item) => {
        const index = animationEvents.indexOf(item);
        if (index > -1) {
            animationEvents.splice(index, 1);
        }
    };

    const handlers = {
        // 水平滑动
        0: {
            initPosition() {
                const $item = $(visibleBannerZoneStr + '.banner_pic_item_wrap');
                $item
                    .hide()
                    .css({
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    })
                    .removeClass('banner_item_actived');
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .addClass('banner_item_actived');
            },
            toggle({ direction, $curBannerItem, $toBannerItem }) {
                setIsInTransition(true);
                $curBannerItem.addClass('higher_zIndex');
                $toBannerItem.show();
                $curBannerItem.removeClass('billboardItem_2_on billboardItem_2_on_reverse');
                $curBannerItem.addClass('billboardItem_2_off');
                $toBannerItem.removeClass('billboardItem_2_off billboardItem_2_off_reverse');
                $toBannerItem.addClass('billboardItem_2_on');

                const { removeEvent } = observeAnimate({
                    el: $curBannerItem[0],
                    endCallBack() {
                        $curBannerItem.removeClass('higher_zIndex');
                        $curBannerItem.hide();
                        setIsInTransition(false);
                        removeAnimationEventItem(removeEvent);
                    },
                });
                animationEvents.push(removeEvent);

                if (direction === -1) {
                    $curBannerItem.addClass('billboardItem_2_off_reverse');
                    $toBannerItem.addClass('billboardItem_2_on_reverse');
                }
            },
        },
        // 淡入淡出
        1: {
            initPosition() {
                $(visibleBannerZoneStr + '.banner_pic_item_wrap')
                    .css({ opacity: '0', transition: `opacity ${config.animateTime() / 1000}s` })
                    .hide()
                    .removeClass('banner_item_actived');
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .css('opacity', '1')
                    .addClass('banner_item_actived');
            },
            async toggle({ $curBannerItem, $toBannerItem }) {
                setIsInTransition(true);
                $toBannerItem.show();
                await jzUtils.wait(0);
                $toBannerItem.css('opacity', '1');
                $curBannerItem.css('opacity', '0');

                $curBannerItem.one('transitionend', function () {
                    $curBannerItem.hide();
                    setIsInTransition(false);
                });
            },
        },
        // 缩小聚焦
        2: {
            initPosition() {
                $(visibleBannerZoneStr + '.banner_pic_item_wrap')
                    .hide()
                    .css({
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    })
                    .removeClass('banner_item_actived')
                    .find('.banner_pic_item, .banner_video_item')
                    .css({
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    });
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .addClass('banner_item_actived');
            },
            toggle({ direction, $curBannerItem, $toBannerItem }) {
                setIsInTransition(true);
                $curBannerItem.addClass('reduce_and_focus_off higher_zIndex');
                $toBannerItem.css('transformOrigin', 'center');
                $toBannerItem.show();
                $toBannerItem.css('overflow', 'hidden');

                $curBannerItem.removeClass('reduce_and_focus_on reduce_and_focus_on_reverse');

                $toBannerItem
                    .addClass('reduce_and_focus_on')
                    .removeClass('reduce_and_focus_off reduce_and_focus_off_reverse');

                const { removeEvent } = observeAnimate({
                    el: $curBannerItem[0],
                    endCallBack() {
                        $toBannerItem.css('overflow', '');
                        $curBannerItem.removeClass('reduce_and_focus_start');
                        $curBannerItem
                            .find('.banner_pic_item, .banner_video_item')
                            .removeClass('reduce_and_focus_scale');
                        $curBannerItem.removeClass('higher_zIndex');
                        $curBannerItem.hide();
                        setIsInTransition(false);

                        removeAnimationEventItem(removeEvent);
                    },
                });
                animationEvents.push(removeEvent);

                const { removeEvent: removeToBannerItemEvent } = observeAnimate({
                    el: $toBannerItem[0],
                    endCallBack() {
                        $toBannerItem.find('.banner_pic_item, .banner_video_item').addClass('reduce_and_focus_scale');

                        setIsInTransition(false);

                        removeAnimationEventItem(removeToBannerItemEvent);
                    },
                });
                animationEvents.push(removeToBannerItemEvent);

                if (direction === -1) {
                    $curBannerItem.addClass('reduce_and_focus_off_reverse');
                    $toBannerItem.addClass('reduce_and_focus_on_reverse');
                }
            },
        },
        // 随机聚焦
        11: {
            initPosition() {
                $(visibleBannerZoneStr + '.banner_pic_item_wrap')
                    .hide()
                    .css({
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    })
                    .removeClass('banner_item_actived')
                    .find('.banner_pic_item, .banner_video_item')
                    .css({
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    });
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .addClass('banner_item_actived billboardItem_11_start');
            },
            toggle({ $curBannerItem, $toBannerItem }) {
                setIsInTransition(true);

                //每次聚焦时，随机选取一个作为transform-origin
                const origins = ['15% 15%', '85% 85%', '15% 85%', '85% 15%'];
                //每次聚焦时，随机选取zoomOut或zoomIn模式
                const zoomModes = ['billboardItem_11_on_zoomOut', 'billboardItem_11_on_zoomIn'];
                const origin = origins[Math.floor(Math.random() * origins.length)];
                const zoomMode = zoomModes[Math.floor(Math.random() * zoomModes.length)];

                $curBannerItem.addClass('higher_zIndex');
                $toBannerItem.show();

                $toBannerItem.addClass(zoomMode);
                $toBannerItem.css('transformOrigin', origin);
                $curBannerItem.removeClass(
                    'billboardItem_11_on_zoomIn billboardItem_11_on_zoomOut billboardItem_11_start'
                );

                const { removeEvent } = observeAnimate({
                    el: $toBannerItem[0],
                    endCallBack() {
                        $curBannerItem.removeClass('higher_zIndex');
                        $curBannerItem.hide();
                        setIsInTransition(false);

                        removeAnimationEventItem(removeEvent);
                    },
                });
                animationEvents.push(removeEvent);
            },
        },
        // 水平3D翻转
        6: {
            initPosition() {
                const $item = $(visibleBannerZoneStr + '.banner_pic_item_wrap');
                const containerWidth = $item.width();
                $item
                    .hide()
                    .css({
                        transformOrigin: `50% 50% ${-containerWidth / 2}px`,
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    })
                    .removeClass('banner_item_actived');
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .addClass('banner_item_actived');
            },
            async toggle({ direction, $curBannerItem, $toBannerItem, animationEnd = () => {} }) {
                setIsInTransition(true);
                $curBannerItem.addClass('higher_zIndex');
                $toBannerItem.show();

                $curBannerItem.removeClass('billboardItem_6_on billboardItem_6_on_reverse');
                $curBannerItem.addClass('billboardItem_6_off');
                $toBannerItem.removeClass('billboardItem_6_off billboardItem_6_off_reverse');
                $toBannerItem.addClass('billboardItem_6_on');

                const { removeEvent } = observeAnimate({
                    el: $curBannerItem[0],
                    endCallBack() {
                        $curBannerItem.removeClass('higher_zIndex');
                        $curBannerItem.hide();
                        setIsInTransition(false);
                        animationEnd();
                        removeAnimationEventItem(removeEvent);
                    },
                });
                animationEvents.push(removeEvent);

                if (direction === -1) {
                    $curBannerItem.addClass('billboardItem_6_off_reverse');
                    $toBannerItem.addClass('billboardItem_6_on_reverse');
                }
            },
        },
        // 垂直3D翻转
        7: {
            initPosition() {
                const $item = $(visibleBannerZoneStr + '.banner_pic_item_wrap');
                const containerHeight = $item.height();
                $item
                    .hide()
                    .css({
                        transformOrigin: `50% 50% ${-containerHeight / 2}px`,
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    })
                    .removeClass('banner_item_actived');
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .addClass('banner_item_actived');
            },
            toggle({ direction, $curBannerItem, $toBannerItem, animationEnd = () => {} }) {
                setIsInTransition(true);
                $curBannerItem.addClass('higher_zIndex');

                $toBannerItem.show();

                $curBannerItem.removeClass('billboardItem_7_on billboardItem_7_on_reverse');
                $curBannerItem.addClass('billboardItem_7_off');
                $toBannerItem.removeClass('billboardItem_7_off billboardItem_7_off_reverse');
                $toBannerItem.addClass('billboardItem_7_on');

                const { removeEvent } = observeAnimate({
                    el: $curBannerItem[0],
                    endCallBack() {
                        $curBannerItem.removeClass('higher_zIndex');
                        $curBannerItem.hide();
                        setIsInTransition(false);
                        animationEnd();
                        removeAnimationEventItem(removeEvent);
                    },
                });
                animationEvents.push(removeEvent);

                if (direction === -1) {
                    $curBannerItem.addClass('billboardItem_7_off_reverse');
                    $toBannerItem.addClass('billboardItem_7_on_reverse');
                }
            },
        },
        // 水平翻转
        4: {
            initPosition() {
                const $item = $(visibleBannerZoneStr + '.banner_pic_item_wrap');
                $item
                    .hide()
                    .css({
                        transformOrigin: `50% 50%`,
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    })
                    .removeClass('banner_item_actived');
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .addClass('banner_item_actived');
            },
            toggle({ direction, $curBannerItem, $toBannerItem, animationEnd = () => {} }) {
                setIsInTransition(true);
                $curBannerItem.addClass('higher_zIndex');
                $toBannerItem.show();

                $curBannerItem.removeClass('billboardItem_4_on billboardItem_4_on_reverse');
                $curBannerItem.addClass('billboardItem_4_off');
                $toBannerItem.removeClass('billboardItem_4_off billboardItem_4_off_reverse');
                $toBannerItem.addClass('billboardItem_4_on');

                const { removeEvent } = observeAnimate({
                    el: $curBannerItem[0],
                    endCallBack() {
                        $curBannerItem.removeClass('higher_zIndex');
                        $curBannerItem.hide();
                        setIsInTransition(false);
                        animationEnd();
                        removeAnimationEventItem(removeEvent);
                    },
                });
                animationEvents.push(removeEvent);

                if (direction === -1) {
                    $curBannerItem.addClass('billboardItem_4_off_reverse');
                    $toBannerItem.addClass('billboardItem_4_on_reverse');
                }
            },
        },
        // 垂直翻转
        5: {
            initPosition() {
                const $item = $(visibleBannerZoneStr + '.banner_pic_item_wrap');
                $item
                    .hide()
                    .css({
                        transformOrigin: `50% 50%`,
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    })
                    .removeClass('banner_item_actived');
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .addClass('banner_item_actived');
            },
            toggle({ direction, $curBannerItem, $toBannerItem, animationEnd = () => {} }) {
                setIsInTransition(true);
                $curBannerItem.addClass('higher_zIndex');

                $toBannerItem.show();

                $curBannerItem.removeClass('billboardItem_5_on billboardItem_5_on_reverse');
                $curBannerItem.addClass('billboardItem_5_off');
                $toBannerItem.removeClass('billboardItem_5_off billboardItem_5_off_reverse');
                $toBannerItem.addClass('billboardItem_5_on');

                const { removeEvent } = observeAnimate({
                    el: $curBannerItem[0],
                    endCallBack() {
                        $curBannerItem.removeClass('higher_zIndex');
                        $curBannerItem.hide();
                        setIsInTransition(false);
                        animationEnd();
                        removeAnimationEventItem(removeEvent);
                    },
                });
                animationEvents.push(removeEvent);

                if (direction === -1) {
                    $curBannerItem.addClass('billboardItem_5_off_reverse');
                    $toBannerItem.addClass('billboardItem_5_on_reverse');
                }
            },
        },
        // 垂直滑动
        3: {
            initPosition() {
                const $item = $(visibleBannerZoneStr + '.banner_pic_item_wrap');
                $item
                    .hide()
                    .css({
                        'animation-duration': `${config.animateTime() / 1000}s`,
                    })
                    .removeClass('banner_item_actived');
                $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
                    .show()
                    .addClass('banner_item_actived');
            },
            toggle({ direction, $curBannerItem, $toBannerItem }) {
                setIsInTransition(true);
                $curBannerItem.addClass('higher_zIndex');
                $toBannerItem.show();
                $curBannerItem.removeClass('billboardItem_3_on billboardItem_3_on_reverse');
                $curBannerItem.addClass('billboardItem_3_off');
                $toBannerItem.removeClass('billboardItem_3_off billboardItem_3_off_reverse');
                $toBannerItem.addClass('billboardItem_3_on');

                const { removeEvent } = observeAnimate({
                    el: $curBannerItem[0],
                    endCallBack() {
                        $curBannerItem.removeClass('higher_zIndex');
                        $curBannerItem.hide();
                        setIsInTransition(false);
                        removeAnimationEventItem(removeEvent);
                    },
                });
                animationEvents.push(removeEvent);

                if (direction === -1) {
                    $curBannerItem.addClass('billboardItem_3_off_reverse');
                    $toBannerItem.addClass('billboardItem_3_on_reverse');
                }
            },
        },
        // 随机
        13: {
            randomType: jzUtils.random(4, 7),
            initPosition() {
                const _handler = handlers[this.randomType];
                $(`.transition_type_13`)
                    .removeClass(`transition_type_4 transition_type_5 transition_type_6 transition_type_7`)
                    .addClass(`transition_type_${this.randomType}`);
                _handler.initPosition();
            },
            async toggle({ direction, $curBannerItem, $toBannerItem }) {
                const _handler = handlers[this.randomType];
                _handler.toggle({
                    direction,
                    $curBannerItem,
                    $toBannerItem,
                    animationEnd: () => {
                        this.randomType = jzUtils.random(4, 7);
                        resetPosition();
                        this.initPosition();
                    },
                });
            },
        },
    };
    const clearAnimationEvents = () => {
        animationEvents.forEach((removeEvent) => removeEvent());
        animationEvents.length = 0;
    };

    const getBannerTransitionHandler = () => {
        const transitionType = config.getTransitionType();

        return handlers[transitionType];
    };

    function resetPosition() {
        const $item = $(visibleBannerZoneStr + '.banner_pic_item_wrap');
        $item
            .stop()
            .css({
                left: '',
                opacity: '',
                transition: '',
                'animation-duration': '',
                transformOrigin: '',
            })
            .removeClass('banner_item_actived')
            .removeClass((i, classes) => {
                return classes
                    .split(/\s+/)
                    .filter((c) => c.includes('on') || c.includes('off') || c.includes('billboardItem'))
                    .join(' ');
            })
            .show();
        $item.find('.banner_pic_item, .banner_video_item').css({
            'animation-duration': '',
        });
        clearAnimationEvents();
    }

    function update(index) {
        // 初始化位置
        if (typeof index != 'undefined') {
            curIndex = banner.config.setCurIndex(index);
        } else {
            curIndex = banner.config.getCurIndex();
        }

        const picLen = $(visibleBannerZoneStr + '.banner_pic_item_wrap').length;
        if (curIndex + 1 > picLen) {
            curIndex = banner.config.setCurIndex(0);
        }

        resetPosition();
        const transitionHandler = getBannerTransitionHandler();
        transitionHandler.initPosition();

        $('.pic_short_line_btn:visible')
            .eq(curIndex)
            .addClass('pic_short_line_btn_activity')
            .siblings()
            .removeClass('pic_short_line_btn_activity');
    }

    function dotSlide(e) {
        if (isInTransition) return false;

        var $this = $(e.currentTarget),
            curIndex = parseInt($('.pic_short_line_btn_activity:visible').attr('index') || 0), // 自动播放时，如果再拿全局的curIndex，是0，why
            to = parseInt($this.attr('index') || 0);

        banner.config.setCurIndex(curIndex);
        slide(to, false, curIndex);
        $this.addClass(shortLineActivity).siblings().removeClass(shortLineActivity);
    }

    function slide(to, direction, currentIndex) {
        curIndex = currentIndex != undefined ? currentIndex : curIndex;
        if (curIndex == to) return;
        if (isInTransition) return false;

        direction = direction || Math.abs(to - curIndex) / (to - curIndex); // 1: backward, -1: forward

        $(function () {
            const $curBannerItem = $(visibleBannerZoneStr + '.banner_pic_' + curIndex);
            const $toBannerItem = $(visibleBannerZoneStr + '.banner_pic_' + to);

            const handler = getBannerTransitionHandler();
            clearAnimationEvents();
            handler.toggle({
                to,
                direction,
                currentIndex,
                $curBannerItem,
                $toBannerItem,
            });

            curIndex = to;
            banner.config.setCurIndex(curIndex);
            $(visibleBannerZoneStr + '.banner_pic_item_wrap').removeClass('banner_item_actived');
            $(visibleBannerZoneStr + '.banner_pic_' + to).addClass('banner_item_actived');
            if (_shortLine.length) {
                _shortLine.eq(to).addClass(shortLineActivity).siblings().removeClass(shortLineActivity);
            }
        });
    }

    let interval;

    function begin() {
        if (window._store.state.manageMode) {
            //管理态需要在某些情况下阻止自动轮播

            //编辑模块
            let moduleId;

            const editPanel = window._store.state.manage.editPanel;
            if (editPanel && editPanel.active) {
                if (editPanel.type === 'module') {
                    //编辑普通模块
                    moduleId = editPanel.id;
                } else if (typeof editPanel.data === 'object') {
                    //可能是常用模块等小面板的
                    moduleId = editPanel.data.id;
                }
            }

            if (window._store.state.manage.editPanel.moduleEditorActiveId) {
                //编辑横幅里面的文本按钮等
                moduleId = window._store.state.manage.editPanel.moduleEditorActiveId;
            }

            if (moduleId && $(`#module${moduleId}`).parents('.jz_banner').length) {
                return;
            }

            //横幅里的模块出现工具栏
            for (const { el } of Root.vm.hoverToolbar) {
                if ($(el).parents('.jz_banner').length) {
                    return;
                }
            }

            //页面弹出了弹窗
            if ($('.fk-popupWindowVT').length) {
                return;
            }

            //在拖动模块
            if ($('.ui-sortable-helper').length) {
                return;
            }

            //准备添加模块
            if ($('.jz_add_drag_placeholder').length) {
                return;
            }

            //调整模块大小和位置
            if ($('#popupBgJzOperatingProtect').length) {
                return;
            }
        }

        interval = setTimeout(nextClick, 0);
    }

    function autoPlay() {
        var timer = banner.config.getTimer();
        // 干掉上一个定时器
        banner.config.clearTimer(timer);
        timer = setInterval(function () {
            begin();
        }, config.intervalTime());
        // 记录当前计时器
        banner.config.setTimer(timer);
    }

    function initAutoPlayEvent() {
        if (config.autoAnimate) {
            $bannerGroup.off('mouseover.m').on('mouseover.m', function () {
                banner.config.clearTimer();
            });
            $bannerGroup.off('mouseout.m').on('mouseout.m', function () {
                autoPlay();
            });
        } else {
            $bannerGroup.off('mouseover.m');
            $bannerGroup.off('mouseout.m');
        }
    }

    function stop() {
        clearTimeout(interval);
    }

    function kill() {
        banner.config.clearTimer();
    }

    return {
        setup() {
            setup();
        },
        slide(to, flag, curIndex) {
            stop();
            slide(to, flag, curIndex);
        },
        prev() {
            stop();
            prevClick();
        },
        next() {
            stop();
            nextClick();
        },
        kill() {
            kill();
        },
        autoPlay() {
            if (config.autoAnimate) {
                autoPlay();
                initAutoPlayEvent();
            }
        },
        update(index) {
            update(index);
        },
    };
};

banner.fixBannerOverflowHeight = function func() {
    if (
        window._store.state.fullScreen &&
        (Comm.getDevice() === 'pc' || window._store.state.responsiveInfo.pattern.rollingScreen.isMobiSync)
    )
        return; // 滚动全屏栏目不fix横幅高度
    const $banner = $('#jz_banner'),
        $picGroup = $banner.find('.banner_pic_group,.banner_flv_area'),
        $rows = $picGroup.find('.jz_web_row__content:visible'),
        initHeight = $picGroup.height();
    let maxFixHeight = 0;
    let maxRowHeight = 0;
    $rows.each(function () {
        const $col = $(this).find('.web_col_content');
        $col.css('min-height', 'auto');
        const rowHeight = $(this).height() - initHeight; //行的真正高度
        const colHeight = $col.height(); //列的高度(在从小变大的时候，列因为有min-height:100%所以会很大)
        const fixHeight = colHeight - rowHeight;
        if (fixHeight > maxFixHeight) {
            maxFixHeight = fixHeight;
        }
        if (colHeight > maxRowHeight) {
            maxRowHeight = colHeight;
        }
        $col.css('min-height', '');
    });
    if (maxFixHeight > 0) {
        $picGroup.height(maxFixHeight);
    } else {
        // 内容区高度未超出横幅高度 则去掉高度值 【自适应横幅优化】 https://www.tapd.cn/42954297/prong/stories/view/1142954297001069420
        $picGroup.css('height', '');
    }
    func._fixHeight = maxRowHeight;
    if (Root._setBannerFixHeight) {
        Root._setBannerFixHeight(maxRowHeight);
    }
};

banner.init = function () {
    const $banner = $('#jz_banner');

    (!window._store.state.fullScreen || Comm.getDevice() === 'mobi') && banner.listenFixBannerHeight();
    $banner.off('click.moduleStopPropagation').on('click.moduleStopPropagation', '.jz_module', function (e) {
        if (!e.originalEvent) return;
        for (let el of e.originalEvent.path) {
            //遍历冒泡过程
            if (el.nodeName == 'A' && el.href) {
                //如果在jz_module里找到a标签则不阻止默认行为
                break;
            }
            if (el.className.includes('jz_module')) {
                //只找到jz_module这一层，如果还没有a标签则阻止默认行为
                e.preventDefault();
                break;
            }
        }
        e.stopPropagation();
    });
};

banner.listenFixBannerHeight = function () {
    banner.fixBannerOverflowHeight();
    setTimeout(function () {
        banner.fixBannerOverflowHeight();
    }, 0);
    $(window)
        .off('resize.listenBanner')
        .on('resize.listenBanner', () => {
            banner.fixBannerOverflowHeight();
        });
    // 监听设备变化，对横幅进行调整
    if (window._store.state.manageMode) {
        banner._unWatchDevice && banner._unWatchDevice();
        banner._unWatchDevice = window._store.watch(
            (state) => state.device,
            () => {
                Vue.nextTick(() => {
                    //节点更变开始后持续fix，直至动画结束
                    //fix间隔为每秒60帧的一半（30帧）
                    const timer = setInterval(() => {
                        banner.fixBannerOverflowHeight();
                    }, (1000 / 60) * 2);
                    device.on(device.EVENTS.ON_CHANGE_AFTER_TRANSITION, () => {
                        clearTimeout(timer);
                    });
                });
            }
        );
    }
};

banner.openLink = function (url, is_blank, event, reqArgs) {
    url = Fai.decodeUrl(url);
    for (var index in event.path) {
        // 如果点击了横幅内模块的链接，则横幅链接不跳转
        if (event.path[index].tagName == 'A') {
            return;
        }
        // 找到横幅链接层截止
        if (event.path[index] == this) {
            break;
        }
    }
    const jump = () => {
        window.open(url, is_blank ? '_blank' : '_self');
    };
    if (getIsOpenRemoveUrlArg() && reqArgs) {
        handleSetUrlArgToCookie(reqArgs).then(() => jump());
    } else {
        jump();
    }
};

export { banner };
