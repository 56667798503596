import {
    getPurchaseVersionUrl,
    getPortalSMSAcctUrl,
    getPortalSMSTemplateUrl,
    getMarketUrl,
    getPortalDnspodTransferUrl,
    getPortalSiteRecordUrl,
    getProtalMPSRecordUrl,
    getMarketCorpGroupBuyUrl,
    getMarketCorpGroupBuySEOUrl,
    getPortalDomainUrl,
} from '@/manage/shared/urlUtils/index.js';
import { browser } from '@jz/utils';
import { createDialog } from './utils';

import { ueHoverStyle } from '@/components/modules/shared/ue/index.js';
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

/* 微信分享弹出函数 */
const wxShareAlter = function (qrCodeUrl) {
    if (window.innerWidth > 768) {
        var contentHtml = [];
        contentHtml.push("<div class='wxShare'>");
        contentHtml.push("<div class='wxShareContent'>");
        contentHtml.push("<div class='wxShareDesc'>");
        //contentHtml.push(			"<span>打开微信\"扫一扫\"，打开网页后点击屏幕右上角分享按钮</span>");
        contentHtml.push("<span style='margin-bottom:50px;display:inline-block;'>" + LS.weChatShare + '</span>'); //文字翻译
        contentHtml.push('</div>');
        contentHtml.push("<div class='wxShareQrcode'><img alt='' src='" + qrCodeUrl + "' /></div>");
        contentHtml.push('</div>');
        contentHtml.push('</div>');

        createDialog({ content: contentHtml.join('') });
    } else {
        if (!browser.isWeChat()) {
            var html = [];
            html.push("<div class='jz_share_cover' style='background-color:white;opacity:1;'>");
            html.push(
                "		<img class='shareCoverIcon shareCoverContent' src='" +
                    window._store.state.resRoot +
                    "/image/rimage/jz/shareIcon.png?v=201703240921'/>"
            );
            html.push("		<div class='shareCoverTip shareCoverContent'>请使用浏览器的分享功能<br/>分享到微信</div>");
            html.push("		<div class='shareCoverBtn shareCoverContent'>知道了</div>");
            html.push('</div>');
            if ($('.jz_share_cover').length == 0) {
                $('#jzWebContainer').append(html.join(''));
                $('.shareCoverBtn').bind('click', function () {
                    $('.shareCoverBtn').unbind();
                    $('.jz_share_cover').remove();
                });
            }
        } else {
            let html = [];
            html.push("<div class='jz_share_cover' style='background: rgba(0,0,0,0.8);'>");
            html.push(
                "		<img class='shareCoverImg' src='" +
                    window._store.state.resRoot +
                    "/image/rimage/jz/shareTip.png?v=201703240921'/>"
            );
            html.push('</div>');
            if ($('.jz_share_cover').length == 0) {
                setTimeout(function () {
                    $('#jzWebContainer').append(html.join(''));
                    $('.jz_share_cover').bind('click', function () {
                        $('.jz_share_cover').unbind().remove();
                    });
                }, 500);
            }
        }
    }
};

/* QQ分享弹出函数 */
const qqShareAlter = function (qrCodeUrl) {
    if (window.innerWidth > 768) {
        window.open(qrCodeUrl);
    } else {
        if (!browser.isQQ()) {
            var html = [];
            html.push("<div class='jz_share_cover' style='background-color:white;opacity:1;'>");
            html.push(
                "		<img class='shareCoverIcon shareCoverContent' src='" +
                    window._store.state.resRoot +
                    "/image/rimage/jz/shareIcon.png?v=201703240921'/>"
            );
            html.push("		<div class='shareCoverTip shareCoverContent'>请使用浏览器的分享功能<br/>分享到QQ</div>");
            html.push("		<div class='shareCoverBtn shareCoverContent'>知道了</div>");
            html.push('</div>');
            if ($('.jz_share_cover').length == 0) {
                $('#jzWebContainer').append(html.join(''));
                $('.shareCoverBtn').bind('click', function () {
                    $('.shareCoverBtn').unbind();
                    $('.jz_share_cover').remove();
                });
            }
        } else {
            let html = [];
            html.push("<div class='jz_share_cover' style='background: rgba(0,0,0,0.8);'>");
            html.push(
                "		<img class='shareCoverImg' src='" +
                    window._store.state.resRoot +
                    "/image/rimage/jz/shareTipQQ.png?v=201703240921'/>"
            );
            html.push('</div>');
            if ($('.jz_share_cover').length == 0) {
                setTimeout(function () {
                    $('#jzWebContainer').append(html.join(''));
                    $('.jz_share_cover').bind('click', function () {
                        $('.jz_share_cover').unbind().remove();
                    });
                }, 500);
            }
        }
    }
};

/**
 * ShareTo
 * function: copyWebSite
 */

const copyWebSite = function () {
    var copy_content = window.location.href;
    var _ie = $.browser.msie;
    if (_ie) {
        window.clipboardData.setData('Text', copy_content);
    } else {
        prompt(LS.shareTips, copy_content);
    }
};

const FitMemberProfilePanelImgSize = function () {};

const _Site = {
    qqShareAlter,
    wxShareAlter,
    copyWebSite,
    FitMemberProfilePanelImgSize,
    getPurchaseVersionUrl,
    getPortalSMSAcctUrl,
    getPortalSMSTemplateUrl,
    getMarketUrl,
    getMarketCorpGroupBuyUrl,
    getMarketCorpGroupBuySEOUrl,
    getPortalDnspodTransferUrl,
    getPortalSiteRecordUrl,
    getProtalMPSRecordUrl,
    getPortalDomainUrl,

    popupBox(options) {
        var settings = {
            boxId: 0,
            title: '',
            htmlContent: '',
            width: 500,
            height: '',
            boxName: '',
            opacity: '0.5',
            displayBg: true,
            autoClose: 0,
            extClass: '',
            popupBoxZIndex: 0,
            needScollTop: true,
            maskClosable: false,
            onMaskClose: null,
            noTitle: false,
        };
        settings = $.extend(settings, options);

        var id = parseInt(settings.boxId);
        if (id == 0 || id == null) {
            id = parseInt(Math.random() * 10000);
        }
        //加背景
        if (settings.displayBg) {
            Fai.bg(id, settings.opacity, settings.popupBoxZIndex);
        }
        var heightStr = '';
        if (settings.height != '') {
            heightStr = ' min-height:' + (settings.height - 45) + 'px;';
        }
        var displayModeContent = [
            "<div id='popupBoxContent",
            id,
            "' style='width:",
            settings.width,
            'px;',
            heightStr,
            ' padding-bottom: 20px;',
            "' class='popupCnBg'>",
            settings.htmlContent,
            '</div>',
        ];
        var browserHeight = Fai.top.document.documentElement.clientHeight;
        var scrollTop = Fai.top.$('body').scrollTop();
        if (scrollTop <= 0) {
            scrollTop = Fai.top.$('html').scrollTop();
        }
        if (scrollTop <= 0) {
            scrollTop = $(window).scrollTop();
        }
        var fromTLStyle = '';
        var closeStyle = '';
        var boxExtStyle = '';
        if (settings.boxName == 'qqLogin') {
            fromTLStyle = "style='padding-top:20px;'";
            closeStyle = "style='margin-top:20px;'";
        } else if (settings.boxName == 'addrInfo') {
            fromTLStyle = "style='margin-top:21px;'";
            //closeStyle = "style='margin-top:24px;'";
        } else if (settings.boxName == 'mallAmountZero') {
            fromTLStyle = "style='height:15px;'";
            closeStyle = "style='margin-top:10px;'";
        } else if (settings.boxName == 'confirmReceipt') {
            closeStyle = "style='margin-top:10px;'";
        } else if (settings.boxName == 'mallBuy') {
            closeStyle = "style='margin-top:10px'";
        } else if (settings.boxName == 'memberFdPwd') {
            fromTLStyle = "style='margin-top:20px;'";
            closeStyle = "style='margin-top:20px'";
        } else if (settings.boxName == 'bannerV2') {
            closeStyle = "style='margin-top:18px;margin-right:18px;'";
        }

        if (settings.popupBoxZIndex) {
            boxExtStyle = 'z-index:' + settings.popupBoxZIndex;
        }

        var popupBoxHtml = [
            "<div id='popupBox",
            id,
            "' class='formBox " + settings.extClass + "' style='width:",
            settings.width,
            'px;',
            heightStr,
            'left:',
            (Fai.top.document.documentElement.clientWidth - settings.width) / 2,
            'px; ' + boxExtStyle + "'>",
            ...(settings.noTitle === false
                ? [
                      "<div class='formTLSite'><div ",
                      fromTLStyle,
                      "class='formTCSite ",
                      settings.title == '' ? 'formMulLanSite' : '',
                      "'>",
                      settings.title,
                      '</div></div>',
                  ]
                : []),
            "<div class='formMSG' style='position:relative;'>",
            displayModeContent.join(''),
            '</div>',
            "<a href='javascript:void(0);' class='formXSite popupBClose' hidefocus='true' onclick='return false;'",
            closeStyle,
            '></a>',
            '</div>',
        ];
        popupBoxHtml = popupBoxHtml.join('');
        var popupWin = Fai.top.$(popupBoxHtml).appendTo('body');
        var topMar = (browserHeight - settings.height) / 2;
        if (settings.height == '') {
            topMar = (browserHeight - $('.popupCnBg').height()) / 2;
        }
        $(popupWin).css('top', settings.needScollTop ? topMar + scrollTop : topMar);
        var clientHeight = Fai.top.$('#popupBg' + id).outerHeight(true),
            iframeHeight = Fai.top.$('#popupBoxContent' + id).outerHeight(true) + 120,
            topHeight = 100 - (iframeHeight - clientHeight) > 0 ? 100 - (iframeHeight - clientHeight) : 0,
            isMobi = Fai.top.$('html').hasClass('jz_screen_mobi');
        if (!isMobi && clientHeight < iframeHeight) {
            popupWin.css('top', topHeight + 'px');
        }
        popupWin.hide();
        popupWin.fadeIn();
        //内部方法，判断是否是函数
        function isFunction(value) {
            return Object.prototype.toString.call(value) === '[object Function]';
        }

        popupWin.ready(function () {
            isFunction(settings.popupWinReadyCallBack) && settings.popupWinReadyCallBack();
            if (settings.maskClosable) {
                Fai.top
                    .$('#popupBg' + id)
                    .off('click.close')
                    .on('click.close', function () {
                        if (isFunction(settings.closeFunc)) {
                            settings.closeFunc();
                        }
                        Fai.top.$('#popupBg' + id).remove();
                        popupWin.remove();
                        if (options.reload) {
                            location.reload(true);
                        }
                        settings.onMaskClose && settings.onMaskClose();
                    });
            }

            popupWin.find('.popupBClose').bind('click', function () {
                if (isFunction(settings.closeFunc)) {
                    settings.closeFunc();
                }
                Fai.top.$('#popupBg' + id).remove();
                popupWin.remove();
                if (options.reload) {
                    location.reload(true);
                }
            });
        });

        if (settings.autoClose != 0) {
            setTimeout(function () {
                popupWin.find('.popupBClose').click();
            }, settings.autoClose);
        }
        return popupWin;
    },
};

const _Jz = {
    ueHoverStyle,
};

export { _Jz, _Site };
