const getAjaxUrl = function (url) {
    var beg = '';

    if (document.location.pathname.indexOf('/manage/') >= 0) {
        beg = '../';
    } else if (document.location.pathname.indexOf('/manage_v2/') >= 0) {
        beg = '../';
    } else {
        beg = '';
    }

    beg = Site.addRequestPrefix({
        newPath: '/',
        oldPath: beg,
    });
    return beg + url;
};

/**
 * checkElement
 * 检查是否为HTMLElement
 * @author mackie
 * @param {*}
 * @returns {Boolean}
 */
const checkElement = (ele) => {
    return jzUtils.type(ele) === 'object'
        ? ele instanceof HTMLElement
        : ele && jzUtils.type(ele) === 'object' && ele.nodeType === 1 && jzUtils.type(ele.nodeName) === 'string';
};

/**
 * 获取页面产生滚动条的节点
 * @retruns {EventTarget}
 */
const getPageScrollTarget = () => {
    return window._store.getters['popupZone/isEditMode']
        ? $('.jz_popup_zone')[0]
        : window._store.state.manageMode && Comm.getDevice() == 'mobi'
        ? $('#jzPreviewContent')[0]
        : window;
};

/*
 ** 检查锚点是否存在
 ** retrun true: 存在
 */

const tick = function (fn) {
    let times = 0;
    const tickInterval = setInterval(() => {
        fn();
    }, 1000 / 60);

    const clearTick = () => clearInterval(tickInterval);

    return {
        clearTick,
        times,
    };
};
/**
 * @function name - detectAnimationEvents
 * @description
 * @author mikey.chuhui
 * @date 2020-11-04
 * @returns {Object} 事件名称对象
 */

let detectAnimationEvents = () => {
    let t;
    let el = document.createElement('fakeelement');
    let animEndEventNames = {
        animation: { end: 'animationend', start: 'animationstart' },
        WebkitAnimation: {
            end: 'webkitAnimationEnd',
            start: 'webkitAnimationStart',
        },
        OAnimation: { end: 'oAnimationEnd', start: 'oAnimationStart' },
        msAnimation: { end: 'MSAnimationEnd', start: 'MSAnimationStart' },
        MozAnimation: { end: 'mozAnimationEnd', start: 'mozAnimationStart' },
    };
    for (t in animEndEventNames) {
        if (el.style[t] !== undefined) {
            detectAnimationEvents = () => animEndEventNames[t];
            return animEndEventNames[t];
        }
    }
};

/**
 * @function name - observeAnimate
 * @author mikey.chuhui
 * @description 动画开始结束事件监听
 * @param options {Object} {el, startCallBack, endCallBack, eventDes = 0}
 */
let animateEvent;
let observeAnimate = ({ el, startCallBack, endCallBack, eventDes = 0 }) => {
    if (!animateEvent) {
        animateEvent = detectAnimationEvents();
    }
    $(el).one(`${animateEvent.start}.${eventDes}`, startCallBack).one(`${animateEvent.end}.${eventDes}`, endCallBack);

    return {
        removeEvent: () => {
            $(el)
                .off(`${animateEvent.start}.${eventDes}`, startCallBack)
                .off(`${animateEvent.end}.${eventDes}`, endCallBack);
        },
    };
};

export { getAjaxUrl, checkElement, getPageScrollTarget, tick, observeAnimate };
