import { debounce } from '@jz/utils';

const responseNav = debounce(function () {
    if (!window._store.state.responsiveInfo.pattern.nav.navadapt) {
        return;
    }

    if (window._store.state.manageMode) {
        return;
    }

    $('#navResponse').remove();
    $('#navResponseFont').remove();

    if (window._store.getters.isMobi) {
        return;
    }

    const viewAreaWidth = this.getViewAreaWidth();
    const navWidth = this.totalItemWidth();

    const isOverflow = navWidth > viewAreaWidth;

    if (!isOverflow) {
        return;
    }

    let delta = navWidth - viewAreaWidth;

    // #region 如果导航展示不全，先缩小左右边距
    const $nav = $('.J_nav');
    const navMarginLeft = parseInt($nav.css('margin-left'));

    let newMarginLeft;
    let newMarginRight;

    if (navMarginLeft > 0) {
        if (delta < navMarginLeft) {
            newMarginLeft = navMarginLeft - delta;
        } else {
            newMarginLeft = 0;
        }
        delta -= navMarginLeft;
    }
    const navMarginRight = parseInt($nav.css('margin-right'));

    if (navMarginRight > 0 && delta > 0) {
        if (delta < navMarginRight) {
            newMarginRight = navMarginRight - delta;
        } else {
            newMarginRight = 0;
        }
        delta -= navMarginRight;
    }

    if (delta > 0) {
        newMarginLeft = 0;
        newMarginRight = 0;
    }

    const marginLeftCss = typeof newMarginLeft === 'number' ? `margin-left: ${newMarginLeft}px !important;` : '';
    const marginRightCss = typeof newMarginRight === 'number' ? `margin-right: ${newMarginRight}px !important;` : '';
    // #endregion

    // 缩小导航项宽度
    let itemWidthCss = '';
    if (delta > 0) {
        const $navItems = $('.jz_nav_list .jz_nav_item');

        $navItems.find('.nav_item_text').css('display', 'inline');

        const remainWidthArr = [];

        let isDoubleLangNav = $('.lang_wrapper').length > 0;

        $navItems.each((index, item) => {
            const $item = $(item);
            const itemWidth = $item.width();
            const itemOuterWidth = $item.outerWidth();
            const $text = $item.find('.nav_item_text');
            // 双语导航，文字宽度取外层宽度
            const $mainText = $item.find('.nav_main_name');
            const $subText = $item.find('.nav_sub_name');
            const textWidth = isDoubleLangNav ? Math.max($mainText.outerWidth(), $subText.outerWidth()) : $text.width();

            const remainWidth = itemWidth - textWidth - (itemOuterWidth - itemWidth);
            remainWidthArr[index] = {
                itemWidth,
                remainWidth,
                calculated: false,
                shouldShrink: 0,
                colId: $item.attr('_colid'),
            };
        });

        while (remainWidthArr.some((item) => item.calculated === false)) {
            const noCalculatedArr = remainWidthArr.filter((item) => !item.calculated);
            const averageRemainWidth = delta / noCalculatedArr.length;
            const lessThanAverageRemainWidthArr = remainWidthArr.filter(
                (item) => item.remainWidth < averageRemainWidth && !item.calculated
            );
            lessThanAverageRemainWidthArr.forEach((item) => {
                item.calculated = true;
                item.shouldShrink = item.remainWidth;
                delta -= item.remainWidth;
            });

            if (lessThanAverageRemainWidthArr.length === 0) {
                remainWidthArr.forEach((item) => {
                    if (item.calculated) {
                        return;
                    }
                    item.calculated = true;
                    item.shouldShrink = averageRemainWidth;
                    delta -= averageRemainWidth;
                });
            }
        }

        $navItems.find('.nav_item_text').css('display', '');
        itemWidthCss = remainWidthArr.reduce((acc, item) => {
            const finalWidth = item.itemWidth - item.shouldShrink;
            return `
            ${acc}
            .jz_nav_item[_colid="${item.colId}"] {
                width: ${finalWidth}px !important;
                min-width: ${finalWidth}px !important;
            }
        `;
        }, '');
    }

    $(`
        <style id="navResponse">
            .J_nav {
                ${marginLeftCss}
                ${marginRightCss}
            }

            ${itemWidthCss}
        </style>
    `).appendTo('head');

    this.updateNavViewArea();

    Vue.nextTick(() => {
        if (this.nextArrowVisible) {
            // 还有箭头的话只能调整字体了
            const fontSize = parseInt($('.jz_nav_item .nav_item_text').css('font-size'));
            const newFontSize = getFontSize(fontSize);
            $(`
                <style id="navResponseFont">
                    #jzPreviewWrap .jz_nav_item .nav_item_text {
                        font-size: ${newFontSize}px !important;
                    }
                </style>
            `).appendTo('head');
            this.updateNavViewArea();
        }
    });
}, 16);

function getFontSize(fontSize) {
    const windowWidth = window.innerWidth;
    let rate;
    if (windowWidth >= 768 && windowWidth < 1440) {
        rate = 0.875;
    } else if (windowWidth >= 1441 && windowWidth < 2559) {
        rate = 1.0;
    } else if (windowWidth >= 2560 && windowWidth < 3839) {
        rate = 1.34;
    } else {
        rate = 2;
    }
    return fontSize * rate;
}

export { responseNav };
