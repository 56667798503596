
import { mapState } from 'vuex';

/**
 * 导航项名称组件
 *  - 支持双语显示
 *
 * data dependencies:
 *  - 双语导航开关
 *  - 双语导航样式
 *
 */
export default {
    name: 'NavName',
    props: {
        /**
         * 主栏目名称
         */
        name: {
            type: String,
            default: '',
        },
        /**
         * 副栏目名称
         */
        subName: {
            type: String,
            default: 'Nav',
        },
        nameStyle: {
            type: Object,
            default: () => ({}),
        },
        /***
         * 副栏目样式
         */
        subNameStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isLinear: false,
        };
    },
    computed: {
        ...mapState(['responsiveInfo', 'device']),
        nav() {
            return this.responsiveInfo.pattern.nav;
        },
        doubleLangOpen() {
            // 开启双语导航 && PC 视图 && 常规导航
            return (this.nav.cdl ?? false) && this.device === 'pc' && this.nav.type === 0;
        },
        doubleLangStyle() {
            return this.nav.cdls ?? 0;
        },
    },
    methods: {
        renderDoubleLang() {
            const classes = ['lang_wrapper', 'nav_name_wrapper', `lang_style_${this.doubleLangStyle}`];
            return (
                <div class={classes} ref="NavNameRef">
                    <div class="nav_main_name" style={this.nameStyle}>
                        {this.name}
                    </div>
                    <div class="nav_sub_name" style={this.subNameStyle}>
                        {this.subName}
                    </div>
                </div>
            );
        },
    },
    render() {
        return this.doubleLangOpen ? (
            this.renderDoubleLang()
        ) : (
            <span class="nav_main_name nav_name_wrapper" ref="NavNameRef" style={this.nameStyle}>
                {this.name}
            </span>
        );
    },
};
