var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DynamicCSS",
    { on: { "on-update": _vm.update } },
    [
      _c("GlobalFont"),
      _vm._v(" "),
      _c("ModuleStyles"),
      _vm._v(" "),
      _c("RowStyles"),
      _vm._v(" "),
      _c("Banner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }