var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ChildrenCSS", [
    _vm._v(
      "\n    " +
        _vm._s(_vm.initialTabFontCss) +
        "\n    " +
        _vm._s(_vm.initialTabBgCss) +
        "\n    " +
        _vm._s(_vm.initialTabBorderCss) +
        "\n    " +
        _vm._s(_vm.activeTabFontCss) +
        "\n    " +
        _vm._s(_vm.activeTabBgCss) +
        "\n    " +
        _vm._s(_vm.activeTabBorderCss) +
        "\n\n    " +
        _vm._s(_vm.tabWrapBorder) +
        "\n    " +
        _vm._s(_vm.tabItemRadius) +
        "\n\n    " +
        _vm._s(_vm.verticalLayoutCss) +
        "\n    " +
        _vm._s(_vm.verticalSizeCss) +
        "\n    " +
        _vm._s(_vm.verticalSpaceCss) +
        "\n    " +
        _vm._s(_vm.verticalTabWrapBgCss) +
        "\n    " +
        _vm._s(_vm.shadowCss) +
        "\n\n    " +
        _vm._s(_vm.pictureTabPicCss) +
        "\n    " +
        _vm._s(_vm.pictureTabSize) +
        "\n    " +
        _vm._s(_vm.pictureTabBorderRadius) +
        "\n    " +
        _vm._s(_vm.picturePicScale) +
        "\n    " +
        _vm._s(_vm.picturePicSize) +
        "\n    " +
        _vm._s(_vm.pictureSpace) +
        "\n\n    " +
        _vm._s(_vm.foldTabFont) +
        "\n    " +
        _vm._s(_vm.foldTabItemBg) +
        "\n    " +
        _vm._s(_vm.foldTabItemHeight) +
        "\n    " +
        _vm._s(_vm.foldTabBorder) +
        "\n    " +
        _vm._s(_vm.activeFoldTabFont) +
        "\n    " +
        _vm._s(_vm.activeFoldTabItemBg) +
        "\n    " +
        _vm._s(_vm.activeFoldTabBorder) +
        "\n    " +
        _vm._s(_vm.foldTabSpace) +
        "\n    " +
        _vm._s(_vm.foldTabItemRadios) +
        "\n    " +
        _vm._s(_vm.TabContentBg) +
        "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }