import { FontIndexedDb } from './fontIndexedDb.js';
import { browser } from '@jz/utils';
import { getFontFaceList } from '@/api/font/index.js';

const addFontFaceToHead = (customFontFaceFamily, substring, resUrl) => {
    if (!customFontFaceFamily || !substring) return;
    const fontInfo = Site.analysisCustomFontFaceFamily(customFontFaceFamily);
    let resSrc = '';
    // resUrl是在初始化的时候调用
    if (resUrl) {
        // 这里特殊处理，资源文件缺失会返回no-pic的图片-_-
        if (resUrl.includes('no-pic')) return;
        resSrc = resUrl;
    } else {
        substring = Fai.unique(substring);
        resSrc = `/font.jsp?cmd=getSubStringFont&substring=${encodeURIComponent(substring)}&id=${
            fontInfo.id
        }&_TOKEN=${$('#_TOKEN').attr('value')}`;
    }
    const fontFaceString = `
        <style type="text/css" id="${customFontFaceFamily}" subString="${substring}">
            @font-face {
                font-family: "${customFontFaceFamily}";
                src: url("${resSrc}") format("truetype");
                font-weight: normal;
                font-style: normal;
            }
        </style>
    `;
    $('head').append(fontFaceString);

    // 这里有个很坑爹的BUG，您要preload font face文件，
    // 单单设置font-family还不行，还需要设置文本，
    // 没有文本的空元素设置font-family是不会preload字体文件的，
    // 元素的属性也不能设置为none，所以在这里设置了一个元素来preload字体文件。
    if ($('#tmpPreloadFontFace').length === 0) {
        const tempFontDiv = $('<div id="tmpPreloadFontFace"></div>').css({
            height: 0,
            width: 0,
            overflow: 'hidden',
        });
        $('body').append(tempFontDiv);
    }
    $('#tmpPreloadFontFace').css('font-family', `${customFontFaceFamily}`).text(substring);
};

const resetHeadFontFace = (customFontFaceFamily, substring, callback) => {
    const oldFontData = Site.analysisCustomFontFaceFamily(customFontFaceFamily);
    const newFontFamily = Site.initCustomFontFaceFamily(oldFontData.name, oldFontData.id);
    Site.addFontFaceToHead(newFontFamily, substring);
    callback && callback(newFontFamily);
};

const initCustomFontFaceFamily = (name, id) =>
    `fontface__${name}__${id}__${(Math.random() * +new Date()).toFixed(0)}`.replace(/\s/g, '');

const analysisCustomFontFaceFamily = (fontFamily) => {
    if (!fontFamily) return {};
    const [flag, name, id, random] = fontFamily.split('__');
    return { flag, name, id, random };
};

const checkFontFaceChange = ($fontsEl) => {
    if (Site.isSupportFontCache()) return;
    $fontsEl &&
        $fontsEl.each((i, e) => {
            let fontFamily = $(e).css('font-family');
            const reg = /fontface__(.*?)__(\d+)__(\d+)/;
            const regData = reg.exec(fontFamily);
            if (regData && regData[0]) {
                fontFamily = regData[0];
            }
            const fontText = Fai.unique($(e).text() || '');
            if (fontFamily.split('__')[0] === 'fontface') {
                let loadedFont = '';
                $(`#${fontFamily}`).each((i, e) => {
                    loadedFont += $(e).attr('substring');
                });
                // 如果用户增加了字体，重新请求字体
                if (fontText != loadedFont) {
                    if (fontText.length < 450) {
                        const textArr = fontText.split('');
                        if (loadedFont) {
                            for (const text of textArr) {
                                if (!loadedFont.includes(text)) {
                                    Site.resetHeadFontFace(fontFamily, fontText, (newFontFamily) => {
                                        if ($(e)[0]) {
                                            const tagType = $(e)[0].tagName.toLocaleLowerCase();
                                            if (tagType == 'font') {
                                                $(e).attr('face', newFontFamily);
                                            } else {
                                                $(e).css('font-family', newFontFamily);
                                            }
                                        }
                                    });
                                    break;
                                }
                            }
                        } else {
                            Site.resetHeadFontFace(fontFamily, fontText, (newFontFamily) => {
                                if ($(e)[0]) {
                                    const tagType = $(e)[0].tagName.toLocaleLowerCase();
                                    if (tagType == 'font') {
                                        $(e).attr('face', newFontFamily);
                                    } else {
                                        $(e).css('font-family', newFontFamily);
                                    }
                                }
                            });
                        }
                    } else {
                        Fai.ing('由于当前文本内容过长将导致浏览器响应缓慢，建议拆分不同文本模块实现或删减文本内容。');
                    }
                }
            }
        });
};

const formatFontList = (fontList) => {
    /**
     * type: 0->中文字体，1->英文字体
     * fontType: 0->系统字体，1->免费字体，2->商用字体(凡科网臻享字体)
     */
    const tipsMsp = {
        free: {
            tipsTitle: '免费字体',
            tipsDesc:
                '字体厂商或个人设计师向全社会开<br>放授权，可免费商用。<br>注：部分低版本浏览器会使用系统<br>默认字体。',
        },
        system: {
            tipsTitle: '系统字体',
            tipsDesc: '计算机自带字体，在网页中以<br>font-family的形式可免费商用。',
        },
        commercial: window._store.state.oem
            ? {
                  tipsTitle: '平台字体',
                  tipsDesc:
                      '平台字体是平台已向字体厂商购<br>买了授权，提供给用户在平台免<br>费使用的正版字体。<br>注：部分低版本浏览器会使用系统<br>默认字体。',
              }
            : {
                  tipsTitle: '凡科网臻享字体',
                  tipsDesc:
                      '臻享字体是凡科网已向字体厂商购<br>买了授权，提供给用户在凡科网平<br>台免费使用的正版字体。<br>注：部分低版本浏览器会使用系统<br>默认字体。',
              },
    };
    const chineseFont = {
        name: '中文',
        type: 0,
        fontTypeList: [
            { fontType: 0, name: '系统字体', ...tipsMsp.system, list: [] },
            { fontType: 1, name: '免费字体', ...tipsMsp.free, list: [] },
            {
                fontType: 2,
                name: window._store.state.oem ? '平台字体' : '凡科网臻享字体',
                ...tipsMsp.commercial,
                list: [],
            },
        ],
    };
    const englishFont = {
        name: '英文',
        type: 1,
        fontTypeList: [
            { fontType: 0, name: '系统字体', ...tipsMsp.system, list: [] },
            { fontType: 1, name: '免费字体', ...tipsMsp.free, list: [] },
            {
                fontType: 2,
                name: window._store.state.oem ? '平台字体' : '凡科网臻享字体',
                ...tipsMsp.commercial,
                list: [],
            },
        ],
    };
    const fonts = [chineseFont, englishFont];
    fontList.forEach((e) => {
        const { type, fontType, isDev = false } = e;
        // isDev 只限制线上
        if (isDev && !Fai.top.window.location.host.includes('.faidev.cc')) {
            return;
        }
        const typeItem = fonts.find((e) => type == e.type);
        const fontTypeItem = typeItem && typeItem.fontTypeList.find((e) => e.fontType == fontType);
        fontTypeItem && fontTypeItem.list.push(e);
    });
    return fonts;
};

let fontListPromise = null;
const getFontList = async () => {
    const { list } = await (fontListPromise || (fontListPromise = getFontFaceList()));
    return list;
};

// 保存时候去合并字体, 并且去重
const mergeFonts = ($el) => {
    if (!$el) throw 'Site.mergeFonts: 请传入$el';
    const fontList = [];
    const $fontsEl = $el.find('*[style*=font-family], font');
    $fontsEl &&
        $fontsEl.each((i, e) => {
            let fontFamily = $(e).css('font-family');
            const reg = /fontface__(.*?)__(\d+)__(\d+)/;
            const regData = reg.exec(fontFamily);
            if (regData && regData[0]) {
                fontFamily = regData[0];
            }
            const { flag, id, random, name } = Site.analysisCustomFontFaceFamily(fontFamily);
            const fontText = $(e).text() || '';
            if (flag === 'fontface') {
                // 同一个字体合并，并且进行字符串去重
                const fontData = fontList.find((e) => e.fontId == id);
                if (fontData) {
                    fontData.substring += fontText;
                    fontData.substring = Fai.unique(fontData.substring);
                    changeFontStyle(e, `${name}, ${fontData.fontFamily}`);
                } else {
                    if (fontText) {
                        fontList.push({
                            id: random,
                            fontId: +id,
                            fontFamily,
                            substring: Fai.unique(fontText),
                        });
                    }
                    const notFirstNumberName = /^\d/.test(name) ? `_${name}` : name;
                    changeFontStyle(e, `${notFirstNumberName}, ${fontFamily}`);
                }
            }
        });
    return fontList.filter((font) => font.substring !== ' ' && font.substring !== '\n');

    function changeFontStyle(el, fontName) {
        if ($(el).is('font')) {
            $(el).attr('face', fontName);
        } else {
            $(el).css('font-family', fontName);
        }
    }
};

// 等待字体加载完成
const onFontFaceLoad = (callback, time = 700) => {
    // safari浏览器竟然不支持document.fonts.onloadingdone的回调!!
    const isSafari = browser.isSafari();
    if (document.fonts && !isSafari) {
        document.fonts.onloadingdone = () => {
            document.fonts.onloadingdone = null;
            callback && callback();
        };
    } else {
        // 尽量避免字体加载时,选中字体闪烁的情况
        setTimeout(() => {
            callback && callback();
        }, time);
    }
};

// 获取字体数据库并开启
const getFontIndexedDb = () => {
    return new Promise((resolve, reject) => {
        // 确保已经从数据库里面拿到所有的字体
        if (window.fontIndexedDb.checkGetFontListDone()) {
            resolve(window.fontIndexedDb);
            return;
        }
        window.fontIndexedDb.getFontListPromise
            .then(() => {
                resolve(window.fontIndexedDb);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

// 是否支持字体缓存，不支持则走原逻辑
const isSupportFontCache = () => {
    function isDef(value) {
        return typeof value !== 'undefined';
    }
    return (
        isDef(window.FontFace) &&
        isDef(window.document.fonts) &&
        isDef(window.XMLHttpRequest) &&
        isDef(window.indexedDB)
    );
};

const initFontFileDb = () => {
    if (typeof FontIndexedDb === 'undefined' || !Site.isSupportFontCache()) {
        return;
    }
    window.fontIndexedDb = new FontIndexedDb();
    Site.getFontIndexedDb();
};

const getFontFileByAjax = (name) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'arraybuffer';
        xhr.open('GET', `${window._store.state.resRoot}/css/dist/fonts/${name}.woff2?v=201911051716`, true);
        xhr.onload = function (e) {
            if (this.status == 200) {
                resolve(this.response);
            } else {
                reject(this.status, e);
            }
        };
        xhr.onerror = function (err) {
            reject(err);
        };
        xhr.send();
    });
};

function addFontToDocumentFons(font) {
    // console.log('加载字体=>', font);
    return new Promise((resolve) => {
        const { fontName, arrayBuffer } = font;
        const fontFace = new FontFace(fontName, arrayBuffer);
        fontFace.load().then((loadedFace) => {
            document.fonts.add(loadedFace);
            resolve();
        });
    });
}
// 页面进来初始化字体文件
const loadedFontIdList = [];
const initFontFace = (fontList, callback) => {
    if (!Array.isArray(fontList)) return;
    fontList.forEach((fontData) => {
        const { fontFamily, substring, resUrl } = fontData;
        Site.addFontFaceToHead(fontFamily, substring, resUrl);
    });
    if (!Site.isSupportFontCache()) return;
    // 字体缓存逻辑，延迟执行
    setTimeout(() => {
        Site.getFontIndexedDb().then((db) => {
            const loadedList = db.fontList || [];
            fontList.forEach((font) => {
                const { fontId, fontFamily } = font;
                let { id, name } = Site.analysisCustomFontFaceFamily(fontFamily);
                // 如果是数字开头的，font-family是不允许的，所以加_
                if (/^(\d)/.test(name)) name = '_' + name;
                const callbackData = { fontName: name };
                if (fontId == -1) return;
                if (loadedFontIdList.includes(fontId)) {
                    callback && callback(callbackData);
                    return;
                }
                const dbFont = loadedList.find((item) => item.fontId == fontId);
                if (dbFont) {
                    // 数据库有该字体，加入document.fonts并直接使用
                    addFontToDocumentFons(dbFont).then(() => {
                        callback && callback(callbackData);
                    });
                } else {
                    // 没有则初始化，缓存到数据库
                    const fontItem = {
                        useFontFace: true,
                        id: parseInt(id),
                        name,
                    };
                    Site.addFontFile(fontItem, () => {
                        callback && callback(callbackData);
                    });
                }
                loadedFontIdList.push(fontId);
            });
        });
    }, 700);
};

// 加载整个字体并存入indexedDb
const addFontFile = async (fontItem, callback) => {
    const { useFontFace, id } = fontItem;
    let name = fontItem.name.replace(/\s/g, '');
    // 如果是数字开头的，font-family是不允许的，所以加_
    if (/^(\d)/.test(name)) name = '_' + name;
    if (!Site.isSupportFontCache() || !useFontFace) return;
    try {
        const fontDb = await Site.getFontIndexedDb();
        const dbFontItem = fontDb.fontList.find((item) => item.fontId == id);
        if (!dbFontItem) {
            const fontNameByAjax = name.replace(/^_/, ''); // 区分系统字体，所以加_，但是服务器存的字体文件是没有_的，所以去掉
            // 不在数据库里请求资源
            const fontData = await Site.getFontFileByAjax(fontNameByAjax);
            await addFontToDocumentFons({
                fontName: name,
                arrayBuffer: fontData,
            });
            await fontDb.addFont(id, name, fontData);
        } else if (!loadedFontIdList.includes(id)) {
            // 在数据库，但没有加入document.fonts，加入一下
            await addFontToDocumentFons({
                fontName: dbFontItem.fontName,
                arrayBuffer: dbFontItem.arrayBuffer,
            });
            loadedFontIdList.push(id);
        }
        callback && callback();
    } catch (err) {
        console.error(err);
    }
};

// 保存时过滤掉管理态优化体验加的font-family，比如（宋体, fontface__宋体__3__1507658858366），要去掉宋体
const filterFontFamily = (content = '') => {
    if (!content) return content;
    const $content = $(`<div>${content}<div>`);
    const $fontsEl = $content.find('*[style*=font-family], font');
    $fontsEl.each((i, e) => {
        const fontFamily = $(e).attr('face') || $(e).css('font-family') || '';
        if (!fontFamily) return;
        const [font, fontface] = fontFamily.replace(/\s/g, '').split(',');
        if (!fontface) return;
        const { flag, name } = Site.analysisCustomFontFaceFamily(fontface);
        if (flag === 'fontface' && font == name) {
            if ($(e).attr('face')) {
                $(e).attr('face', fontface);
            } else {
                $(e).css('font-family', fontface);
            }
        }
    });
    return $content.html();
};
const filterEmptyElem = (content = '') => {
    if (!content) return content;
    const $content = $(`<div>${content}<div>`);
    $content.children('div:empty').remove();
    return $content.html();
};

export {
    addFontFaceToHead,
    resetHeadFontFace,
    initCustomFontFaceFamily,
    analysisCustomFontFaceFamily,
    checkFontFaceChange,
    formatFontList,
    getFontList,
    mergeFonts,
    onFontFaceLoad,
    getFontIndexedDb,
    isSupportFontCache,
    initFontFileDb,
    getFontFileByAjax,
    initFontFace,
    addFontFile,
    filterFontFamily,
    filterEmptyElem,
};
